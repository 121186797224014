<template lang="pug">
    div.welcome-page
        div.img-container
            img.logo(src="/img/logo-header.png")
        div.consent-block
            p.text-averta-light-32 {{$t("data.privacy")}}
            div.content-text
                p.text-averta-regular-20 At Wats, your privacy is important to us, and we place great care in the responsible, transparent, and safe handling of data.
                p.text-averta-regular-20 Wats has contracted with the company {{companyName}} to provide its business applications to specific users.
                p.text-averta-regular-20 Regarding Data processing and regulations, Wats is and only is a Data Processor. The client is the data Controller to
                    a(href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/rules-business-and-organisations/obligations/controller-processor/what-data-controller-or-data-processor_en" target="_blank") the sense
                    span defined by the European Commission.
                p.text-averta-regular-20 Wats is responsible as a Data Processor of its applications and all the data treatment linked. In no cases Wats is responsible for the Content shared in its applications.
                p.text-averta-regular-20 This privacy notice applies to users of Wats applications (Web, in Teams...) and describes the types of personal data and information collected, how it is used, how long it is processed and stored, how it is protected, with whom it is shared, to whom it can be transferred it and the rights that you can exercise regarding the use of your personal data on this platform. We also describe how to contact us to further enquire about these privacy practices or to exercise your rights. You can click on one of the links below to jump to the listed section:
                ul
                    li.subtitle-averta-regular-18
                        a(href='#1') •	Your Rights
                    li.subtitle-averta-regular-18
                        a(href='#2')  •	Information Collected
                    li.subtitle-averta-regular-18
                        a(href='#3') •	How the Information Collected is Used
                    li.subtitle-averta-regular-18
                        a(href='#4') •	Third Party Tools and Resources
                    li.subtitle-averta-regular-18
                        a(href='#5') •	Information Shared
                    li.subtitle-averta-regular-18
                        a(href='#6') •	How the Data is Processed, Stored and Protected
                    li.subtitle-averta-regular-18
                        a(href='#7') •	Updates to this Privacy Notice
                    li.subtitle-averta-regular-18
                        a(href='#8') •	How to Contact Wats

                p.subtitle-averta-regular-18(id="1") Your Rights
                p.text-averta-regular-20 When authorized by applicable law, a data subject may exercise certain specific rights, such as:
                p.text-averta-regular-20 •	Right of access: A data subject may access his or her personal data to verify that his or her personal data is processed in accordance with law.
                p.text-averta-regular-20 •	Right to rectification: A data subject may request the rectification of any inaccurate or incomplete data held about him or her, to protect the accuracy of such information and to adapt it to the data processing.
                p.text-averta-regular-20 •	Right to erasure: A data subject may request that the Data Controller erases information about him or her and to no longer process that data.
                p.text-averta-regular-20 •	Right to restriction of processing: A data subject may request that the Data Controller restricts the processing of his or her data.
                p.text-averta-regular-20 •	Right to data portability: A data subject may request data portability, meaning that the data subject can receive the originally provided personal data in a structured and commonly used format or that the data subject can request the transfer of the data to another data controller.
                p.text-averta-regular-20 •	Right to object: A data subject who provide a Data Controller with personal data may object, at any time, to the data processing on a number of grounds as set out under GDPR without needing to justify his or her decision.
                p.text-averta-regular-20 •	Right not to be subject of automated individual decision-making: A data subject may request not to be subject to a decision based solely on automated processing, including profiling, if such profiling produces a legal effect concerning the data subject or similarly significantly affects him or her.
                p.text-averta-regular-20 •	Right to lodge a complaint with a supervisory authority: Every data subject has the right to lodge a complaint with an applicable supervisory authority; in particular in the EU Member State of his or her habitual residence, place of work or place of the alleged infringement if the data subject considers that the processing of personal data relating to him or her infringes GDPR.
                p.text-averta-regular-20 Whenever data processing is based on consent as described in Article 7 of the GDPR, the data subject may withdraw his or her consent at any time.
                p.text-averta-regular-20 If you require more information about the processing of your personal data, please refer to the
                    a How to Contact Wats
                    span section below.
                p.subtitle-averta-regular-18(id="2") Information Collected
                p.text-averta-regular-20 We may collect a selection of personal data, including, but not limited to:
                p.text-averta-regular-20 •	Contact information (like full name, professional email address, professional telephone number) – with your consent.
                p.text-averta-regular-20 •	Information you provide during correspondence with us, via telephone, email or otherwise, including any information provided within materials you upload to the site.
                p.text-averta-regular-20 •	Information you provide when you register on Wats applications, which may include a personal description and photograph - with your consent;
                p.text-averta-regular-20 All processing will be carried out based on the adequate legal grounds outlined above, including:
                p.text-averta-regular-20 (1) Consent from you.
                p.text-averta-regular-20 (2) Fulfilment of contract.
                p.text-averta-regular-20 (3) Legitimate business purpose, for example, letting you access the website to be provided with the services offered, or to enhance, modify, personalise, or otherwise improve our services/communications for your benefit.
                p.text-averta-regular-20 Whenever we process data for legitimate business purposes, we will make sure to keep your rights in high regard and you have the right to object to such processing. More details can be found in the section titled,
                    a(href="#1") Your Rights
                    span . Please be advised that if you exercise your right to object, this may affect our ability to carry out and deliver services to you for your benefit.
                p.subtitle-averta-regular-18(id="3") How the Information Collected is Used
                p.text-averta-regular-20 Wats uses the data gathered for the following purposes (as permitted under local law):
                p.text-averta-regular-20 •	To comply with contractual obligations and our policies (the use of Wats applications by your company.
                p.text-averta-regular-20 None of your data will be used for anything not related to our purpose with you and your company.
                p.subtitle-averta-regular-18(id="4") Third Party Tools and Resources
                p.text-averta-regular-20 When we use the services of other parties to process data on our behalf, due diligence takes place to ensure they are GDPR compliant and, where necessary, that processing agreements are in place with these parties, so that they too handle your personal data carefully. We never sell your data to third parties.
                p.subtitle-averta-regular-18(id="5") Information Shared
                p.text-averta-regular-20 We do not disclose eventual personal or professional data that we collect about you, except as described in this privacy notice.
                p.text-averta-regular-20 In addition, we may disclose personal data about you on the following basis:
                p.text-averta-regular-20 (1) If we are required to do so by law or legal process.
                p.text-averta-regular-20 (2) When we believe disclosure is necessary or appropriate to prevent physical harm or financial loss, or in connection with an investigation of suspected or actual fraudulent or illegal activity.
                p.subtitle-averta-regular-18(id="6") How the Data is Processed, Stored and Protected
                p.text-averta-regular-20 Your personal data will be retained for the duration of the contract between Wats and your employer. However, depending on your local country law, you may have the right to request we remove your personal data before that point.
                p.text-averta-regular-20 Administrative, technical, and physical safeguards designed to protect the personal data you provide against accidental, unlawful, or unauthorised destruction, loss, alteration, access, disclosure, or use, are diligently maintained.
                p.text-averta-regular-20 User account data is stored and backed up in the E.U.
                p.subtitle-averta-regular-18(id="7") Updates to this Privacy Notice
                p.text-averta-regular-20 This privacy notice (including any addenda) may be updated periodically to reflect changes in our privacy practices and legal updates.
                p.subtitle-averta-regular-18(id="8") Questions or Queries regarding your Data?
                p.text-averta-regular-20 If you have any questions/comments about this privacy notice or if you would like to exercise your rights, please write to us at:
                p.subtitle-averta-regular-18 Wats
                p.subtitle-averta-regular-18 85 boulevard Pasteur
                p.subtitle-averta-regular-18 75015 PARIS, France
                a.subtitle-averta-regular-18(href="support@wats.ai" target="_blank") support@wats.ai
                p.text-averta-regular-20 Version date: May, 2022
            div.input-container
                label.text-averta-regular-20(for='checkbox_consent') {{$t("data.privacy.accept")}}
                    input(type="checkbox" id="checkbox_consent" v-model="isChecked")
                    span.checkmark
            div.button-block
                button.button.btn-primary-square(:disabled="!isChecked" @click="confirm") {{$t('next')}}
                p.text-averta-regular-20.contact-us Dear all, please contact us at knowledge@scor.com for any technical issue.

        
</template>

<script>

export default {
    name: "WelcomePage",
    data() {
        return {
            isChecked: false,
            companyName: process.env.VUE_APP_COMPANY_NAME
        }
    },
    methods: {
        confirm() {
            this.$router.push('/step1');
        }
    }
}
</script>

<style scoped lang="scss">
.welcome-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: url("/img/welcomepage.png");
    background-size: cover;
    height: 100vh !important;
    overflow: hidden !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    position: relative;
    margin-left: 0 !important;
    width: 100vw !important;
    .logo {
        position: absolute;
        top: 46px;
        left: 46px;
        width: 160px;
        filter: var(--svg-filter-white-color);
    }

    .consent-block {
        width: 500px;
        background: white;
        border-radius: 5px;
        padding: 27px 30px;
        height: 375px;
        position: relative;

        .content-text {
            height: 200px;
            overflow-y: scroll;
            margin-top: 20px;

            p {
                line-height: 1.5;
                word-break: keep-all;

                a {
                    margin: 0 5px;
                }
            }

            .text-averta-regular-20 {
                margin-bottom: 5px;
                margin-top: 5px;
            }

            ul {
                line-height: 1.5;

                a {
                    cursor: pointer;
                    color: var(--text-color);
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

        }

        .input-container {
            display: flex;
            align-items: center;
            position: relative;
            margin-top: 35px;

            label {
                padding-left: 25px;
                cursor: pointer;

                input {
                    position: absolute;
                    opacity: 0;
                    border: #ffffff;
                    margin-right: 14px;
                    border: 1px solid var(--black-color);
                    display: none !important;

                    &:checked {
                        & ~ .checkmark {
                            border: none;
                            background-color: var(--primary-color);
                            height: 15px;
                            width: 15px;
                            &:after {
                                display: block;
                                border: solid var(--white-color);
                                border-width: 0 1px 1px 0;
                            }
                        }
                    }
                }

                .checkmark {
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    height: 13px;
                    width: 13px;
                    background-color: white;
                    border-radius: 2px;
                    border: 1px solid var(--black-color);

                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 5px;
                        top: 2px;
                        width: 3px;
                        height: 8px;
                        border: solid var(--black-color);
                        border-width: 0 1px 1px 0;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        .button-block {
            margin-top: 35px;
            button {
                float: right;
            }
            .contact-us {
                position: absolute;
                bottom: -25px;
                left: 0;
                color: var(--white-color);
            }
        }
    }
    
}
</style>